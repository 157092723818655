import React, { useEffect, useState } from 'react';
import {Container, Grid, Checkbox, Typography} from "@mui/material";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Paid, ScaleOutlined, ShoppingCart, Place, Inventory } from "@mui/icons-material";
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CustomTextField1 from "../components/CustomInput/CustomInput";
import CustomAutocompleteField from "../components/CustomInputAutocomplete/CustomInputAutocomplete";
import CustomSelectField from "../components/CustomInputSelect/InputSelect"
import CustomModal from "../components/CustomModal/CustomModal";
import "./Calculator.css";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { opcionesDepartamentos } from "../components/opcionesDepartamentos";
import axios from 'axios';
import LoadingButtonCalcular from "../components/LoadingButton/LoadingButton";
import useDebounce from "../../hooks/useDebounce";
import ReCAPTCHA from 'react-google-recaptcha';
import Lock from "../../images/Lock.png";
import Sorpresa from "../../images/Sorpresa.png";
import SwitchSelector from "../components/SwitchSelector/switch-selector";
import { useDatosFinalPrice } from '../../context/DatosFinalPrice'
import useRedirectIframe from "../../hooks/useRedirectIframe";

function Calculator() {
  const [valorComercial, setValorComercial] = useState('');
  const [peso, setPeso] = useState(null);
  const [seguro, setSeguro] = useState(false);
  const [productType, setProductType] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [velocidad, setVelocidad] = useState('');
  const [ciudadesFiltradas, setCiudadesFiltradas] = useState([]);
  const [errores, setErrores] = useState({});
  const [mostrarIconos, setMostrarIconos] = useState(false);
  const [isPesoDisabled, setIsPesoDisabled] = useState(false);
  const [cargando, setCargando] = useState(false);
  const { changeDatosFinalPrice} = useDatosFinalPrice();
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
  const [openInsuranceModal, setOpenInsuranceModal] = useState(false);
  const [openHighValueModal, setOpenHighValueModal] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [todosCamposRellenados, setTodosCamposRellenados] = useState(false);

  useRedirectIframe('https://wuuju.co/pages/calculadora');

  const productTypeOptions = [
    { value: 'celular', label: 'Celular' },
    { value: 'laptop', label: 'Laptop' },
    { value: 'tablet', label: 'Tablet' },
    { value: 'torre', label: 'PC Escritorio ya ensamblado' },
    { value: 'otro', label: 'Otro' },
  ];

  /* Logicas para funcionamiento Switch */

  const switchOptions = [
    { label: "Personal Shopper", value: "shopper" },
    { label: "Casillero", value: "locker" }
  ];

  const [serviceValue, setServiceValue] = useState(switchOptions[0].value);

  const handleServiceChange = (newValue) => {
    setServiceValue(newValue);
  };

  let serviceText;

  if (serviceValue === switchOptions[0].value) {
    serviceText = (
        <>
          🛒 Cuando haces tus pedidos en la modalidad de <strong>PERSONAL SHOPPER</strong> nos <strong>encargamos de todo</strong>: Encontrar el mejor proveedor, hacer las compras por ti y llevártelas a casa.
        </>
    );
  } else {
    serviceText = (
        <>
          📦 Cuando haces pedidos en la modalidad de <strong>CASILLERO</strong> tú haces las compras de los productos y nosotros nos <strong>encargamos únicamente </strong>de llevártelas a casa.
        </>
  )
  }

  /* Logicas de validaión de campos */

  const renderCheckIcon = (campo, alignRight = false) => {
    if (mostrarIconos) {
      if (errores[campo] === 'error') {
        return (
          <CancelIcon
            style={{
              color: '#FB8065',
              marginLeft: alignRight ? 'auto' : 0,
              marginRight: alignRight ? 0 : 'auto',
              fontSize: '1.5vw',
            }}
          />
        );
      } else if (isCampoRellenado(campo)) {
        return (
          <CheckCircleIcon
            style={{
              color: '#6CE9B1',
              marginLeft: alignRight ? 'auto' : 0,
              marginRight: alignRight ? 0 : 'auto',
              fontSize: '1.5vw',
            }}
          />
        );
      }
    }
    return null;
  };

  const isCampoRellenado = (campo) => {
    if (campo === null || campo === undefined) {
      return false;
    }

    if (typeof campo === 'string' || typeof campo === 'number') {
      return campo.toString().trim() !== '';
    }

    if (typeof campo === 'object' && campo.hasOwnProperty('label')) {
      return campo.label.trim() !== '';
    }

    return false;
  };


   const handleSubmit = (event) => {
    event.preventDefault();

    const nuevosErrores = {};

    if (!isCampoRellenado(valorComercial)) {
      nuevosErrores['valorComercial'] = 'error';
    }
    if (!valorComercial || isNaN(Number(valorComercial))) {
      nuevosErrores['valorComercial'] = 'error';
    }
    if (Number(valorComercial) >= 2000 || (Number(valorComercial) * Number(cantidad)) >= 2000) {
      nuevosErrores['valorComercial'] = 'error';
      showHighValueModal();
    }
    if (!isCampoRellenado(productType)) {
      nuevosErrores['productType'] = 'error';
    }
    if (!isCampoRellenado(cantidad)) {
      nuevosErrores['cantidad'] = 'error';
    }
    if (!isCampoRellenado(departamento)) {
      nuevosErrores['departamento'] = 'error';
    }
    if (!isCampoRellenado(ciudad)) {
      nuevosErrores['ciudad'] = 'error';
    }
    if (!isCampoRellenado(velocidad)) {
      nuevosErrores['velocidad'] = 'error';
    }
    if (!isCampoRellenado(peso) && !isPesoDisabled) {
      nuevosErrores['peso'] = 'error';
    }

    setErrores(nuevosErrores);
    setMostrarIconos(true);

    if (
      Object.keys(nuevosErrores).length > 0 ||
      !isCampoRellenado(departamento) ||
      !isCampoRellenado(ciudad) ||
      !isCampoRellenado(velocidad)
    ) {
      toast.error('No tan rápido McQueen, por favor llena todos los campos.', {
        style: {
          marginTop: '40px', width: '300px', left: '-1%', 
        },
      });
    } else {
      setCargando(true);

      const dataToSend = {
        usd_price: valorComercial,
        product_type: productType,
        count: cantidad,
        weight: peso,
        city: ciudad,
        token: captchaValue,
        service: serviceValue,
        speed: velocidad,
      };

      sendAPIRequest(dataToSend)
        .then((data) => {
          changeDatosFinalPrice(data);
        })
        .catch((error) => {
          console.error('Error al realizar la solicitud:', error);
          toast.error(`Error: ${error.message}`, {
            style: {
              marginTop: '40px',
              width: '300px',
              left: '-1%',
            },
          });
        })
        .finally(() => {
          setCargando(false);
        });
    }
  };

  const sendAPIRequest = (dataToSend) => {
    return axios.post('https://back.wuuju.co/calculator/api/update_shipping_rates/', dataToSend, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        response.data.insurance = seguro;
        response.data.service = serviceValue;
        return response.data;
      })
      .catch(error => {
        console.error('Error al realizar la solicitud:', error);
        throw error;
      });
  };

  const handleChangeProductType = (event) => {
    const selectedProductType = event.target.value;
    setProductType(selectedProductType);

    const isDisabled = ['laptop', 'celular', 'tablet'].includes(selectedProductType);
    setIsPesoDisabled(isDisabled);
    if (isDisabled) {
      setVelocidadOptions([{ value: 'lento', label: 'Estándar (6-9 días hábiles)٭' }]);
      setVelocidad('lento');
    } else {
      setVelocidadOptions([
        { value: 'rapido', label: 'Express (96 horas hábiles)٭' },
        { value: 'lento', label: 'Estándar (6-9 días hábiles)٭' },
      ]);
      setVelocidad('');
    }
  };
  const handleButtonClick = () => {
    window.open('https://wa.link/hlpo5u', '_blank');
    setOpenHighValueModal(false);
  };


  const handleChangeDepartamento = (event) => {
    const departamentoSeleccionado = event.target.value;
    const departamento = opcionesDepartamentos.find((dep) => dep.value === departamentoSeleccionado);
    setCiudadesFiltradas(departamento ? departamento.ciudades : []);
    setDepartamento(event.target.value);
  };

  const handleChangeCiudad = (event) => {
    setCiudad(event.target.value);
  };

  const handleChangeVelocidad = (event) => {
    setVelocidad(event.target.value);
  };

  const [velocidadOptions, setVelocidadOptions] = useState([
    { value: 'rapido', label: 'Express (96 horas hábiles)' },
    { value: 'lento', label: 'Estándar (6-9 días hábiles)' },
  ]);

  useEffect(() => {
    if (Number(cantidad) > 5 && cantidad !== '') {
      setVelocidadOptions([{ value: 'lento', label: 'Estándar (6-9 días hábiles)٭' }]);
      setVelocidad('lento');
    } else if (Number(valorComercial) >= 200
      || (Number(valorComercial) * Number(cantidad) >= 200) ||['tablet', 'laptop', 'celular'].includes(productType))  {
      setVelocidadOptions([{ value: 'lento', label: 'Estándar (6-9 días hábiles)٭' }]);
      setVelocidad('lento');
    } else {
      setVelocidadOptions([
        { value: 'rapido', label: 'Express (96 horas hábiles)٭' },
        { value: 'lento', label: 'Estándar (6-9 días hábiles)٭' },
      ]);
      setVelocidad('');
    }
  }, [cantidad, valorComercial]);

  const debouncedValorComercial = useDebounce(valorComercial, 500);

  useEffect(() => {
    const valorComercialNum = parseFloat(valorComercial);
    const cantidadNum = parseFloat(cantidad);


    // Verificar que valorComercial no esté vacío y la multiplicación supere los 300
    if ((valorComercial || !isNaN(valorComercialNum)) && valorComercialNum * cantidadNum >= 300) {
      setOpenInsuranceModal(true);

      // Permitir desmarcar la checkbox cuando valorComercial está vacío
      setIsCheckboxDisabled(true);
      setSeguro(true);
    } else {
      setOpenInsuranceModal(false);

      // Permitir desmarcar la checkbox cuando valorComercial está vacío
      setIsCheckboxDisabled(false);
      setSeguro(false);
    }
  }, [debouncedValorComercial, cantidad]);


  const handleChangeSeguro = () => {
    if (!isCheckboxDisabled) {
      setSeguro(!seguro);
    }
  };

  const showHighValueModal = () => {
    setOpenHighValueModal(true);
  };

  // Captcha
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  useEffect(() => {
    const campos = [valorComercial, peso, productType, cantidad, departamento, ciudad, velocidad];
    const todosRellenados = campos.every((campo, index) => {
      if (index === 1 && isPesoDisabled) {
        return true; // Si el peso está desactivado, no es necesario verificarlo.
      }
      return isCampoRellenado(campo);
    });
    setTodosCamposRellenados(todosRellenados);
  }, [valorComercial, peso, productType, cantidad, departamento, ciudad, velocidad, isPesoDisabled]);

  return (
    <>
      <Container className='calculator-container-new'>
        <ToastContainer position="top-center"  />
        <form onSubmit={handleSubmit}>

          <Grid container rowSpacing={0} columnSpacing={2} columns={16}>

            {/* Titulo*/}
            <Grid item xs={16}  sx={{ px: 0 }}>
              <Grid container spacing={2} alignItems="center" >
                <Grid item xs={12}>
                  <Typography
                      variant="h1"
                      sx={{
                        marginBottom: { xs: '2vw', sm: 'auto' },
                        marginTop: { xs: '2vw', sm: 'auto' },
                        color: '#5452e1',
                        fontFamily: 'Viga, sans-serif',
                        fontSize: {xs: '6.4vw', sm: '2vw'},
                        textAlign: 'center',
                        lineHeight: 'normal',
                      
                      }}
                  >
                    Calcula el costo de traer <br/> tus compras a Colombia
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/*Seleccion modalidad*/}
            <Grid container style={{ justifyContent: 'center' }} direction="column" alignItems="center" >
              <Grid item>
                <Typography
                    sx={{
                      marginBottom: { xs: '2vw', sm: 'auto' },
                      marginTop: { xs: '2vw', sm: 'auto' },
                      color: '#8e8e93',
                      fontFamily: 'Viga, sans-serif',
                      fontSize: {xs: '4vw', sm: '0.8vw'},
                      textAlign: 'center',
                      lineHeight: 'normal',
                      
                    }}
                >
                  Selecciona una modalidad
                </Typography>
              </Grid>
              <Grid container sx={{ justifyContent: 'center', padding: 'px 0', marginLeft: { xs: '2.2vw', sm: 'auto' }}}>
                <SwitchSelector options={switchOptions} onValueChange={handleServiceChange} />
              </Grid>
              <Grid item sx={{width: {xs: '100%', sm: '70%'}}
            }>
                <Typography
                    sx={{
                      marginBottom: { xs: '2vw', sm: 'auto' },
                        marginTop: { xs: '3vw', sm: 'auto' },
                      color: '#5452e1',
                      fontFamily: 'Viga, sans-serif',
                      fontSize: {xs: '2.5vw', sm: '0.7vw'},
                      textAlign: 'center',
                      lineHeight: 'normal',
                      marginLeft: {xs: '2.6vw', sm: 'auto'}
                    }}
                >
                  {serviceText}
                </Typography>
              </Grid>
            </Grid>

            {/* Valor Comercial */}
            <Grid item xs={16} sm={8}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {renderCheckIcon('valorComercial')}
                </Grid>
                <Grid item xs={12} sm={10}>
                  <CustomTextField1
                    placeholder="Valor Comercial"
                    startIcon={<Paid />}
                    endIcon={null}
                    unit="USD"
                    value={valorComercial}
                    setValue={setValorComercial}
                    type="number"
                    customStyles={{
                      '& input[type=number]': {
                        '-moz-appearance': 'textfield'
                      },
                      '& input[type=number]::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0
                      },
                      '& input[type=number]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Tipo Producto */}
            <Grid item xs={16} sm={8}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={10}>
                  <CustomSelectField
                      placeholder="Tipo de producto"
                      startIcon={<ShoppingCart />}
                      options={productTypeOptions}
                      value={productType}
                      onChange={handleChangeProductType}
                      hideValidation={true}
                  />
                </Grid>
                <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {renderCheckIcon('productType')}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={16} sm={8}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {renderCheckIcon('peso', true)}
                </Grid>
                <Grid item xs={12} sm={10}>
                  <CustomTextField1
                      placeholder="Peso del producto"
                      startIcon={<ScaleOutlined className={`MuiSvgIcon-root  ${isPesoDisabled ? 'disabled-price' : ''}`} />}
                      endIcon={null}
                      unit="Lbs"
                      value={isPesoDisabled === true ? '' : peso}
                      setValue={setPeso}
                      disabled={isPesoDisabled}
                      type="number"
                      customStyles={{
                        '& input[type=number]': {
                          '-moz-appearance': 'textfield'
                        },
                        '& input[type=number]::-webkit-outer-spin-button': {
                          '-webkit-appearance': 'none',
                          margin: 0
                        },
                        '& input[type=number]::-webkit-inner-spin-button': {
                          '-webkit-appearance': 'none',
                          margin: 0
                        }
                      }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={16} sm={8}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={10}>
                  <CustomTextField1
                      placeholder="Unidades por referencia"
                    startIcon={<Inventory />}
                    endIcon={null}
                    unit={null}
                    value={cantidad}
                    setValue={setCantidad}
                      hideValidation={true}
                      type="number"
                      customStyles={{
                        '& input[type=number]': {
                          '-moz-appearance': 'textfield'
                        },
                        '& input[type=number]::-webkit-outer-spin-button': {
                          '-webkit-appearance': 'none',
                          margin: 0
                        },
                        '& input[type=number]::-webkit-inner-spin-button': {
                          '-webkit-appearance': 'none',
                          margin: 0
                        }
                      }}
                  />
                </Grid>
                <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {renderCheckIcon('cantidad', true)}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={16} sm={8}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {renderCheckIcon('departamento')}
                </Grid>
                <Grid item xs={12} sm={10}>
                  <CustomAutocompleteField
                      placeholder="Departamento"
                    startIcon={<Place />}
                    options={opcionesDepartamentos}
                    value={departamento}
                    onChange={handleChangeDepartamento}
                      hideValidation={true}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={16} sm={8}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={10}>
                  <CustomAutocompleteField
                      placeholder="Ciudad"
                    startIcon={<Place />}
                    options={ciudadesFiltradas}
                    value={ciudad}
                    onChange={handleChangeCiudad}
                      hideValidation={true}
                  />
                </Grid>
                <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {renderCheckIcon('ciudad', true)}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={16}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item sm={1} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
                <Grid item xs={12} sm={10}>
                  <CustomSelectField
                      placeholder="Velocidad de traida a Colombia"
                    startIcon={<FlightTakeoffIcon />}
                    options={velocidadOptions}
                    value={velocidad}
                    onChange={handleChangeVelocidad}
                      hideValidation={true}
                  />
                </Grid>
                <Grid item sm={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {renderCheckIcon('velocidad')}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={16}>
              <Grid container alignItems="center" sx={{paddingTop: '0.6vw'}}>
                <Grid item sm={1} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
                <Grid item xs={1}>
                  <Checkbox
                    checked={seguro}
                    onChange={handleChangeSeguro}
                    color="primary"
                    className="checkbox"
                    disabled={isCheckboxDisabled}
                    onClick={() => setSeguro(!seguro)}
                    sx={{
                      padding: '0',
                      '& .MuiSvgIcon-root': {
                        marginTop: {xs: '2.3vw', sm: 'auto'},
                        fontSize: {xs: '4vw', sm: '1.5vw'},
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={1} sx={{ display: { xs: 'none', sm: 'none' } }}></Grid>
                <Grid item xs={10} >
                  <Typography
                      sx={{
                        color: '#5452e1',
                        fontFamily: 'Viga, sans-serif',
                        fontSize: {xs: '2.8vw', sm: '0.85vw'},
                        textAlign: 'left',
                        lineHeight: 'normal',
                        paddingTop: {xs: '3vw', sm: '0'},
                        my: 'auto',
                        marginLeft: {sm: '-1.7vw', md:'-1.9vw', lg:'-2.2vw', xl:'-2.3vw'}, 
                      }}
                  >
                  <span className="custom-text">
                    Si deseo asegurar mi paquete.
                  </span>
                  <span
                  
                    className="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open('https://wuuju.co/pages/que-cubre-tu-seguro-de-envio', '_blank');
                    }}
                  >
                    ¿Qué cubre el seguro?
                  </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={16}>
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  <div className="captcha-zone-calculator">
                    <ReCAPTCHA
                        sitekey="6Lf8QDkpAAAAAKxxbB7jIPYY_CSHiZs59bPmUB5P"
                        onChange={handleCaptchaChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Grid>

            <Grid item xs={16}>
              <Grid container justifyContent="center" direction="column" alignItems="center">
                <Grid item sm={1}></Grid>
                <Grid container xs={10}>
                  <LoadingButtonCalcular
                    handleSubmit={handleSubmit}
                    loading={cargando}
                    label="Calcular"
                    disabled={!todosCamposRellenados}
                    isButtonPurple={todosCamposRellenados}
                  />
                </Grid>
                <Grid item sm={1}></Grid>
                <Grid item xs={12}>
                  <Typography
                      sx={{
                        color: '#5452e1',
                        fontFamily: 'Viga, sans-serif',
                        fontSize: {xs: "3vw", sm: '0.8vw'},
                        paddingTop: {xs: "2vw", sm: "0"}
                      }}
                  >
                    ¿Tienes dudas?
                    <span className='whatsapp-icon-container'>
                      <WhatsAppIcon className="whatsapp-icon"
                      sx={{
                        color: "#5452ff",
                        fontSize: '0.9vw',
                      }} />
                    </span>
                    <a
                      href="https://wa.link/hlpo5u"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="whatsapp-link"
                    >
                      Contáctanos
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

              {/* Alertas - Modales */}
              {/* Seguro */}
              <CustomModal
                  open={openInsuranceModal}
                  onClose={() => setOpenInsuranceModal(false)}
                  image={Lock}
                  title={
                      <>
                          Tu seguridad es <br /> nuestra prioridad
                      </>
                  }
                  content={
                      <div>
                          <p className="text-normal">
                              Por ende, todos los paquetes que superen los <span className="purple-text">$300 USD</span> en su valor comercial declarado o en sumatoria,
                              <span className="purple-text">deberán llevar seguro obligatorio. <a href="https://wuuju.co/pages/que-cubre-tu-seguro-de-envio" target="_blank"
                              rel="noopener noreferrer">Enterate acá qué cubre el seguro.</a></span>
                          </p>
                      </div>
                  }
                  buttonText="Entendido"
                  onButtonClick={() => setOpenInsuranceModal(false)}
                  sx={{
                      title: {
                          color: '#5452e1',
                          fontSize: {xs: '6vw', sm: '3vw'},
                          fontFamily: "'Viga', sans-serif",
                          textAlign: 'center',
                          lineHeight: '1',
                          marginTop: '2vw'
                      },
                      content: {
                          fontSize: {xs: '3vw', sm: '1.1vw'},
                          textAlign: 'center',
                          position: 'relative',
                          overflow: 'hidden'
                      },
                      button: {
                          backgroundColor: '#b3df53',
                          color: 'white',
                          width: {xs: '20vw', sm: '10vw'},
                          fontSize: {xs: '2.6vw', sm: '1.2vw'},
                          borderRadius: '2vw',
                          fontFamily: "'Viga', sans-serif",
                          marginBottom: '1vw',
                          '&:hover': {
                              backgroundColor: '#b3df53',
                              filter: 'brightness(105%)',
                          },
                      },
                      image: {
                        
                      },
                  }}
              />


              {/* Mayor a 2000 USD */}
              <CustomModal
                  onButtonClick={handleButtonClick}
                  open={openHighValueModal}
                  onClose={() => setOpenHighValueModal(false)}
                  image={Sorpresa}
                  title={
                      <>
                          <span>Waaaoooo,</span><br />
                          <span>$2.000 usd...ishh</span>
                      </>
                  }
                  content={
                      <div>
                          <p>
                              Debido a que el costo del producto (o la sumatoria de los productos de tu envío) es <span className="purple-text">igual o superior a $2000 USD</span> debemos traerlo bajo una <span className="purple-text">modalidad especial y única</span>. Tú tranqui que te lo llevamos a casa, solo contáctanos y !PAM¡ a tus manos. <br />👇
                          </p>
                      </div>
                  }
                  buttonText={
                      <>
                          <WhatsAppIcon sx={{ fontSize: {xs: '2.7vw', sm: '1vw'}, }} />
                          CONTÁCTANOS
                      </>
                  }
                  sx={{
                      title: {
                          color: '#5452e1',
                          fontSize: {xs: '6vw', sm: '2vw'},
                          fontFamily: "'Viga', sans-serif",
                          textAlign: 'center',
                          lineHeight: '1',
                          marginTop: '1.5vw'
                      },
                      content: {
                        fontSize: {xs: '2.5vw', sm: '1.1vw'},
                        textAlign: 'center',
                          position: 'relative',
                          overflow: 'hidden'
                      },
                      button: {
                          backgroundColor: '#b3df53',
                          color: 'white',
                          width:{xs: '20vw', sm: '10vw'},
                          fontSize: {xs: '2vw', sm: '1vw'},
                          borderRadius: '2vw',
                          fontFamily: "'Viga', sans-serif",
                          marginBottom: '1vw',
                          display: 'flex',
                          '&:hover': {
                              backgroundColor: '#b3df53',
                              filter: 'brightness(105%)',
                          },
                      },
                      image: {
                      },
                  }}
              />
          </Grid>
        </form>
      </Container>
    </>

  );

}

export default Calculator;